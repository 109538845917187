import React, { useContext, useState, useEffect, useRef, memo } from "react";

import {  Container } from "@mui/material";
import { useSelector } from "react-redux";
import Wrapper from "../Wrapper";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import "swiper/css/navigation";

import CompareProductItem from "./CompareProductItem";

const CompareProductList = () => {
  const { t, i18n } = useTranslation();
  const { compareProducts } = useSelector((state) => state.appState);

  return (
    <Wrapper>
      <Container>
        <div style={{display: 'flex',flexWrap: 'wrap'}}>
            {
              compareProducts && compareProducts.length > 0 && (<>
                {
                  compareProducts.map(productDetail => <div style={{width: '50%'}}>
                    <CompareProductItem key={productDetail?.product_id} product={productDetail}/>
                  </div>)
                }
              </>)
            }
        </div>
        <ToastContainer draggable={false} autoClose={3000} />
      </Container>
    </Wrapper>
  );
};

export default memo(CompareProductList);
