import React, { useState, useEffect } from "react";
import "../../mobile/accountSetting/SignupCss.scss";
import { Phone, Lock } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";

import CutomErrorMessage from "../../components/commons/CutomErrorMessage";
import ApiCollections from "../../services/ApiCollections";
import { setAuthUser } from "../../reducers/user";
import { passwordReset } from "../../reducers/userActions";
import { useTranslation } from "react-i18next";

const userInfoSchema = Yup.object({
  // phone: Yup.string().required().label("Phone Number"),
  password: Yup.string().required().min(6).matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,"At least one number, one samll and capital letter").label("New Password"),
  confirm_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});
const ResetPassword = () => {
  const { token, authUser } = useSelector((state) => state.user);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.user);
  const [errors, setErrors] = useState(null);
  let phone_number = authUser
    ? authUser.user
      ? authUser.user.phone_number
      : ""
    : "";
  const { t } = useTranslation();
  useEffect(() => {
    if (token !== null) {
      navigate("/");
    }
    window.scrollTo(0, 0);
  }, [navigate, token]);

  const submitPasswordReset = async ({ password, confirm_password }) => {
    let requestObj = {
      method: "post",
      url: ApiCollections.resetPassword,
      params: {
        phone: phone_number,
        password,
        confirm_password,
      },
    };

    try {
      setErrors(null);
      const response = await dispatch(passwordReset(requestObj)).unwrap();
      if (response.flag === false) {
        setErrors(response.message);
      } else if (response.data.code === 200) {
        toast.success(response.data.message);
        //set aut user data to state
        dispatch(setAuthUser(response.data.data));
        localStorage.setItem("userTokenID", response.data.data.user.id);
        localStorage.setItem("userLoginStatus", "phone");
        navigate("/");
      } else {
        toast.error("Something Wrong on New Password Set!");
      }
    } catch (err) {
      toast.error(`Request failed: ${err.message}`);
    }
  };
  return (
    <div className="signup">
      <ToastContainer autoClose={3000} />
      {error && toast.error(error)}
      {errors &&
        errors.map((error, index) => {
          toast.error(error);
        })}
      <div className="loginTitle">{t("RESET_PASSWORD")}</div>
      <Formik
        initialValues={{ password: "", confirm_password: "" }}
        validationSchema={userInfoSchema}
        onSubmit={(values) => {
          submitPasswordReset(values);
        }}
      >
        {({ handleChange, handleSubmit, errors }) => (
          <Form>
            <div className="userBox">
              <div className="name">{t("phoneNumber")}</div>
              <div className="enterBox">
                <Phone id="photo" />
                <input
                  // name="phone"
                  type="text"
                  value={phone_number}
                  disabled
                  placeholder={t("pleasePhone")}
                  className="inputEnter"
                />
              </div>
            </div>
            <div className="passwordBox">
              <div className="name">{t("NewPassword")}</div>
              <div className="enterBox">
                <Lock id="photo" />
                <input
                  placeholder={t("pleaseNewPassword")}
                  className="inputEnter"
                  name="password"
                  type="password"
                  onChange={handleChange("password")}
                />
              </div>
              {errors.password && <CutomErrorMessage error={errors.password} />}
            </div>
            <div className="passwordBox">
              <div className="name">{t("ConfirmPassword")}</div>
              <div className="enterBox">
                <Lock id="photo" />
                <input
                  placeholder={t("pleaseConfirmPassword")}
                  className="inputEnter"
                  name="confirm_password"
                  type="password"
                  onChange={handleChange("confirm_password")}
                />
              </div>
              {errors.confirm_password && (
                <CutomErrorMessage error={errors.confirm_password} />
              )}
            </div>
            <div className="btnLog" onClick={handleSubmit}>
              {t("Reset")}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPassword;
