import React, { useState, useEffect, memo } from "react";
import "../../mobile/storePage/StoreShopCss.scss";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowCircleLeft } from "@mui/icons-material";
import { Star } from "@mui/icons-material";
import { Input, Space } from "antd";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import InfiniteScroll from "react-infinite-scroll-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../components/commons/Loading";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";

const data =     {
  "id": 4,
  "name": "Test User",
  "phone_numbers": "09776995184",
  "region_id": 12,
  "region_name": "Yangon",
  "is_entire_region": 0,
  "city_id": 255,
  "city_name": "Mingala Taungnyunt",
  "marketplace_service_ids": "[\"6\",\"4\",\"2\",\"5\",\"3\"]",
  "merketplace_services": [
    {
      "id": 2,
      "service_type": "Transportation EN"
    },
    {
      "id": 3,
      "service_type": "Relaxation EN"
    },
    {
      "id": 4,
      "service_type": "Vue EN"
    },
    {
      "id": 5,
      "service_type": "Laravel EN"
    },
    {
      "id": 6,
      "service_type": "React EN"
    }
  ],
  "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  "address": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  "photo_url": "https://test-backend.mgdmall.com.mm/api/show-file/25a1c14d-95bf-4670-99a7-ff9ec9ce3655",
  "created_at": "2025-02-27 22:01:37",
  "merketplace_services_arr": [
    "Transportation EN",
    "Relaxation EN",
    "Vue EN",
    "Laravel EN",
    "React EN"
  ]
}

const ServiceShop = () => {
  const { t } = useTranslation();

  let { shopId } = useParams();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    getStoreShop();
    fetchData();
  }, []);

  const [loading, setLoading] = useState(false);
  const [shopDetail, setShopDetail] = useState(data);
  const getStoreShop = async () => {
    setLoading(true);
    let requestObj = {
      method: "post",
      url: ApiCollections.serviceShops,
      params: {
        id: shopId,
      },
    };
    let response = await ApiRequest(requestObj);
    setLoading(false);

    response = response.data;
    // if (response?.data) {
    //   setShopDetail(response?.data);
    // }
  };
    
  const navigate = useNavigate();
  const goback = () => {
    navigate(-1);
  };

  const fetchData = () => {
    setPage(page + 1);
    getStoreShop();
  };
  return (
    <>
      <ToastContainer autoClose={3000} />
      {loading && <Loading open={loading} />}
      <div className="storeShopDiv">
        <div
          className="header"
          id="topHeader"
          style={{
            backgroundImage: `url(${shopDetail?.photo_url})`,
          }}
        >
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="inputDiv">
            {/* <Space direction="vertical">
              <Search
                placeholder={t("searchStore")}
                onSearch={onSearch}
                enterButton
                id="searchDiv"
              />
            </Space> */}
          </div>
        </div>
        {shopDetail && (
          <div className="storeHeader">
            <div className="logo">
              <img src={shopDetail?.photo_url} className="image" />
            </div>
            <div className="name">{shopDetail?.is_entire_region ? shopDetail?.region_name : shopDetail?.city_name}</div>
          </div>
        )}
        <div className="storeblank">
          <div className="title">{t("Services")}</div>
          <div className="w-full h-auto grid grid-cols-3 gap-x-4 mt-8">
            {
              shopDetail?.merketplace_services.map(service => <>
                <ListItem style={{backgroundColor: '#34a6f7',margin: 5,padding: 2,borderRadius: 10}}>
                  <ListItemButton>
                    <ListItemText primary={service?.service_type} style={{color: 'white'}} />
                  </ListItemButton>
                </ListItem>
                </>)
            }
          </div>
        </div>
        <div className="storeblank">
          <div className="title">{t("Services Area")}</div>
          <div className="w-full h-auto grid grid-cols-3 gap-x-4 mt-8">
            {
              shopDetail?.merketplace_services_arr.map(area => <>
                <ListItem style={{backgroundColor: '#34a6f7',margin: 5,padding: 2,borderRadius: 10}}>
                  <ListItemButton>
                    <ListItemText primary={area} style={{color: 'white'}} />
                  </ListItemButton>
                </ListItem>
                </>)
            }
          </div>
        </div>
        <div className="storeblank">
          <div className="title">{t("Address")}</div>
          <div className="w-full h-auto mt-8">
            {
              shopDetail?.address
            }
          </div>
        </div>
        <div className="storeblank">
          <div className="title">{t("Description")}</div>
          <div className="w-full h-auto mt-8">
            {
              shopDetail?.description
            }
          </div>
        </div>

        <div className="imageDiv">
        </div>
      </div>
    </>
  );
};

export default memo(ServiceShop);
