import React from "react";
import { 
  FavoriteBorder, Visibility, 
  StarOutline, StarRate, StarHalf 
} from '@mui/icons-material';

const StarComponent = ({ rate }) => {
  return (
    rate !== undefined &&
    <div>
      {[...Array(rate[0])].map((e, i) => 
        <span>
          <StarRate fontSize="small" 
            style={{color: '#faca51'}}
          />
        </span>
      )}
      {[...Array(rate[1])].map((e, i) => 
        <span>
          <StarOutline fontSize="small" 
            style={{color: '#faca51'}}
          />
        </span>
      )}
    </div>
  )
}

export default StarComponent;