import React, { memo } from "react";
import Wrapper from "../Wrapper";
import "./CompareProduct.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import Lottie from "lottie-react";
import nodata from "../../assets/nodata.json";
import { useTranslation } from "react-i18next";
import CompareProductCard from "./CompareProductCard";
import Button from '@mui/material/Button';
import { updatecompareProducts } from "../../reducers/appState";

const CompareProduct = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { compareProducts } = useSelector((state) => state.appState);

  const removeCompareProduct = async () => {
    dispatch(updatecompareProducts([]));
    toast.error("Product Remove from Compare!", {
      className: "toastColor",
      draggable: true,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <Wrapper>
      <ToastContainer autoClose={3000} />
      <div className="Cart" style={{paddingLeft: '10px',paddingRight: '10px',paddingBottom: '8vh'}}>
        <div className="header" id="topHeader">
          <div className="title">{t("Compare Product")}</div>
        </div>
        {compareProducts?.length > 0 ? (
          <div>
            {
              compareProducts?.map(product => (<>
                <CompareProductCard key={product?.product_id} detailproduct={product} />
                <div style={{width: '100%',height: '40px',backgroundColor: 'blue'}}></div>
              </>))
            }
              <Button onClick={removeCompareProduct} variant="contained" color="error" style={{width: '100%',height: '7vh',marginTop: 20}}>
                Remove From Compare
              </Button>
          </div>
        ) : (
          <div className="noDataform">
            <div className="text">{t("No Compare Product")}</div>
            <div className="iconWrap">
              <Lottie animationData={nodata} loop={true} id="lot" />
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default memo(CompareProduct);
