import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "../home/Home";
import Categories from "../category/Categories";
import CategoryAllProduct from "../home/CategoryAllProduct";
import GrandAllProduct from "../category/GrandAllProduct";
import ShoppingCart from "../cart/ShoppingCart";
import PersonalCenter from "../account/PersonalCenter";
import ProductDetail from "../product/ProductDetail";
import CheckOut from "../product/CheckOut";
import CheckOutMultiple from "../product/CheckOutMultiple";
import Payment from "../product/Payment";
import MultipleProductPayment from "../product/MultipleProductPayment";
import UserProfile from "../account/UserProfile";
import UserInformation from "../account/UserInformation";
import UserProfileUpdate from "../account/UserProfileUpdate";
import SignupWithPhone from "../accountSetting/SignupWithPhone";
import LoginWithPhone from "../accountSetting/LoginWithPhone";
import SocialLoginCallback from "../accountSetting/SocialLoginCallback";
import LoginWithSms from "../accountSetting/LoginWithSms";
import PopularMore from "../seeMore/PopularMore";
import NewArivial from "../seeMore/NewArivial";
import FlashMore from "../seeMore/FlashMore";
import PromotionMore from "../seeMore/PromotionMore";
import StoreAll from "../seeMore/StoreAll";
import MgdAbout from "../account/MgdAbout";
import TermPolicy from "../account/TermPolicy";
import HowToSell from "../account/HowToSell";
import OrderList from "../account/OrderList";
import OrderDetail from "../account/OrderDetail";
import NoCancelDetail from "../account/NoCancelDetail";
import CancelDetail from "../account/CancelDetail";
import OrderCancel from "../account/OrderCancel";
import OrderCancelList from "../account/OrderCancelList";
import ReadyToShip from "../account/ReadyToShip";
import ToShip from "../account/ToShip";
import Recieve from "../account/Recieve";
import Return from "../account/Return";
import Message from "../account/Message";
import Wishlist from "../account/Wishlist";
import FllowStore from "../account/FllowStore";
import Coupon from "../account/ShowCoupon";
import ReviewPage from "../account/ReviewPage";
import ReviewHistory from "../account/ReviewHistory";
import SeeQA from "../account/SeeQA";
import Comment from "../product/Comment";
import QuestionAndAnswer from "../product/QuestionAndAnswer";
import AddAddress from "../address/AddAddress";
import EditAddress from "../address/EditAddress";
import StoreShop from "../storePage/StoreShop";
import RequestPasswordReset from "../accountSetting/RequestPasswordReset";
import RequestResetPasswordSms from "../accountSetting/RequestResetPasswordSms";
import ResetPassword from "../accountSetting/ResetPassword";
import SearchProduct from "../home/SearchProduct";
import RouteLayout from "./RouteLayout";
import LastLocaltionRoute from "./LastLocaltionRoute";

import AuthProtectedRoute from "./AuthProtectedRoute";
import PaymentMiddleware from "./PaymentMiddleware";
import OrderCancleMiddleware from "./OrderCancleMiddleware";
import PWACallBack from "../../components/commons/PWACallBack";
import KbzPayRedirect from "../../components/commons/KbzPayRedirect";
import BackToMobile from "../../components/commons/BackToMobile";
import CompareProduct from "../compare/CompareProduct";
import ServiceShopAll from "../seeMore/ServiceShopAll";
import ServiceShop from "../storePage/ServiceShop";

const router = () => {
  return (
    <Router>
      <Routes>
        <Route element={<RouteLayout />}>
          <Route element={<LastLocaltionRoute />}>
            <Route exact path="/" element={<Home />} />
            <Route path="Categories" element={<Categories />} />
            <Route
              path="CategoryAllProduct/:categoryId"
              element={<CategoryAllProduct />}
            />
            <Route
              path="GrandAllProduct/:grandId"
              element={<GrandAllProduct />}
            />
            <Route path="ShoppingCart" element={<ShoppingCart />} />
            <Route path="CompareProductMobile" element={<CompareProduct />} />
            <Route path="PersonalCenter" element={<PersonalCenter />} />
            <Route
              path="products/product-detail/:productId/:itemId?"
              element={<ProductDetail />}
            />
            <Route element={<AuthProtectedRoute />}>
              <Route path="CheckOut" element={<CheckOut />} />
              <Route
                path="multiple-product-checkout"
                element={<CheckOutMultiple />}
              />
              <Route element={<PaymentMiddleware />}>
                <Route path="Payment" element={<Payment />} />
              </Route>
              <Route element={<PaymentMiddleware />}>
                <Route
                  path="PaymentMultiple"
                  element={<MultipleProductPayment />}
                />
              </Route>
              <Route path="update-profile" element={<UserProfileUpdate />} />
            </Route>
            <Route path="UserProfile" element={<UserProfile />} />
            <Route path="UserInformation" element={<UserInformation />} />
            <Route path="Signup" element={<SignupWithPhone />} />
            <Route path="login-sms" element={<LoginWithSms />} />
            <Route path="login-with-phone" element={<LoginWithPhone />} />
            <Route path="PopularMore" element={<PopularMore />} />
            <Route path="NewArivial" element={<NewArivial />} />
            <Route path="FlashMore" element={<FlashMore />} />
            <Route path="PromotionMore" element={<PromotionMore />} />
            <Route path="StoreAll" element={<StoreAll />} />
            <Route path="ServiceShopMobile" element={<ServiceShopAll />} />
            <Route path="ServiceShop/:shopId" element={<ServiceShop />} />
            <Route path="MgdAbout" element={<MgdAbout />} />
            <Route path="TermPolicy" element={<TermPolicy />} />
            <Route path="howToSell" element={<HowToSell />} />
            <Route element={<AuthProtectedRoute />}>
              <Route path="OrderList" element={<OrderList />} />
              <Route path="OrderCancelList" element={<OrderCancelList />} />

              <Route element={<OrderCancleMiddleware />}>
                <Route path="OrderCancel/:cancelID" element={<OrderCancel />} />
              </Route>
              <Route path="SeeQA" element={<SeeQA />} />
              <Route path="Recieve" element={<Recieve />} />
              <Route path="ReadyToShip" element={<ReadyToShip />} />
              <Route path="ToShip" element={<ToShip />} />
              <Route path="Return" element={<Return />} />
              <Route path="Wishlist" element={<Wishlist />} />
              <Route path="FllowStore" element={<FllowStore />} />
              <Route path="Coupon" element={<Coupon />} />
              <Route path="ReviewHistory" element={<ReviewHistory />} />
            </Route>
            <Route path="OrderDetail/:orderID" element={<OrderDetail />} />
            <Route
              path="NoCancelDetail/:orderID"
              element={<NoCancelDetail />}
            />
            <Route path="CancelDetail/:orderID" element={<CancelDetail />} />
            <Route path="Message" element={<Message />} />
            <Route
              path="ReviewPage/:reviewOrderId/:reviewProductId"
              element={<ReviewPage />}
            />
            <Route path="Comment/:productId" element={<Comment />} />
            <Route
              path="QuestionAndAnswer/:pid/:sid"
              element={<QuestionAndAnswer />}
            />
            <Route path="AddAddress" element={<AddAddress />} />
            <Route path="EditAddress" element={<EditAddress />} />
            <Route path="StoreShop/:sellerId" element={<StoreShop />} />
            <Route path="auth/:provider" element={<SocialLoginCallback />} />
            <Route path="pwa/payment-success" element={<PWACallBack />} />
            <Route path="mobile/pwa/payment-redirect" element={<KbzPayRedirect />} />
            <Route path="pwa/mobile/back-to-mobile" element={<BackToMobile />} />
            <Route
              path="request-reset-password"
              element={<RequestPasswordReset />}
            />
            <Route
              path="sms-reset-password"
              element={<RequestResetPasswordSms />}
            />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="search-product" element={<SearchProduct />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default router;
