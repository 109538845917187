import React, { useContext, useState, useEffect, memo } from "react";

import { Favorite, Star } from "@mui/icons-material";
import "./pDetail.scss";
import { useDispatch, useSelector } from "react-redux";
import { NavigateContext } from "../context";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";

// Import Swiper styles
import "swiper/css";
import { updatecompareProducts } from "../../reducers/appState";

// import "./styles.css";

const CompareProductItem = ({product}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { compareProducts } = useSelector((state) => state.appState);
  useEffect(() => {
    window.scrollTo(0, 0);
    getProductDetail();
    setImg("");
    setImgIndex(null);
  }, [product]);

  const [forThumbnail, setForThumbnail] = useState([]);
  const [showImg, setShowImg] = useState([]);
  const [img, setImg] = useState("");

  const [detailproduct, setProductDetail] = useState([]);
  const [sameColor, setSameColor] = useState([]);
  const [CateID, setCateID] = useState(null);
  const [serlerID, setSellerID] = useState([]);

  const [shortdes, setShortdes] = useState("");
  const [longdes, setLongdes] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [show, setShow] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const getProductDetail = async () => {
      setProductDetail(product);
      setSameColor([
        ...new Map(
          product.items.map((item) => [item["color"], item])
        ).values(),
      ]);
      setCateID(product.category_id);
      setSellerID(product.seller_id);
      setForThumbnail(product.product_file);
      setShowImg(product.product_file[0].photo);
      setFavProduct(product.favourite_status);
      setLongdes(product.product_longdescription);
      setShortdes(product.product_description);
      setYoutubeLink(product.product_link.substring(17, 28));
      if (product.product_link !== "null") {
        setImgIndex(100);
        setShow(1);
      } else {
        setImgIndex(0);
        setShow(0);
      }
  };

  const [itemDetail, setItemDetail] = useState([]);
  const [showSize, setSize] = useState([]);
  const [isActive, setActive] = useState(null);
  const [activeColor, setActiveColor] = useState(null);

  const [sizeActive, setSizeActive] = useState(null);
  const [sizeAct, setSizeAct] = useState(null);
  const choseColor = async (selectID, selectColor) => {
    setActive(selectID);
    setActiveColor(selectColor);
    let obj = {
      method: "get",
      url: ApiCollections.sizeApi,
      params: {
        id: product?.product_id,
        color: selectColor,
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setSize(response.data);
      setSizeAct("");
      setSizeActive("");
    }
  };

  const [itemName, setItemName] = useState(null);
  const [itemPromotionPrice, setItemPromotionPrice] = useState(null);
  const [itemPrice, setItemPrice] = useState(null);
  const choseSize = async (sizeID, sizeName) => {
    setSizeActive(sizeID);
    setSizeAct(sizeName);
    let obj = {
      method: "get",
      url: ApiCollections.itemDetailApi,
      params: {
        id: sizeID,
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setItemDetail(response.data);
      setItemName(response.data.product_name);
      setItemPrice(response.data.price);
      setItemPromotionPrice(response.data.product_promotion_price);
    }
  };

  const [favProduct, setFavProduct] = useState("0");
  const gofav = async (index) => {
    if (localStorage.getItem("userTokenID") != null) {
      setFavProduct(index);
      let requestObj = {
        method: "post",
        url: ApiCollections.favStatus,
        params: {
          customer_id: localStorage.getItem("userTokenID"),
          favourite_status: index,
          product_id: product?.product_id,
        },
      };
      let response = await ApiRequest(requestObj);

      if (response.data.code === 200) {
        toast.success(response.data.message);
      } else {
        toast.error("Something Wrong when try to follow!");
      }
    } else {
      if (location && location.pathname)
        sessionStorage.setItem("redirectUrl", location.pathname);
      navigate("/login-with-phone");
    }
  };

  const navigate = useContext(NavigateContext);

  const newArray = [];
  let newData = [...forThumbnail];
  while (newData.length > 0) newArray.push(newData.splice(0, 5));

  const [imgIndex, setImgIndex] = useState(null);


  const removeCompareProduct = async () => {
      let productId = product?.product_id;
      if(compareProducts && compareProducts?.length) {
        if(compareProducts.find(product => product.product_id == productId)) {
          let dataCompare = compareProducts.filter(productID => productID.product_id !== productId);
          dispatch(updatecompareProducts(dataCompare));
  
          toast.error("Product Remove from Compare!", {
            className: "toastColor",
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        }
      }
    };
  

  return (
        <div className="product-detail-wrapper overflow-hidden">
          <div className="swiper-wrapper pt-5">
            <div className="swiper-div">
              <div className="w-[100%] h-[450px] mx-auto showImg relative">
                <div className="w-full">
                  <div className="img-zoom-container">
                    <img
                      id="myimage"
                      src={img ? img : showImg && showImg}
                      className="small-img"
                      tabIndex={0}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="w-[51.17%] h-auto">
              <div className="product-title !text-[1.675rem] !text-black pr-8">
                {itemName ? itemName : detailproduct.product_name}
              </div>
              <div className="price mt-[15px]">
                {itemName ? (
                  <>
                    {itemPromotionPrice !== 0 ? (
                      <>
                        <span className="current-price">
                          <span className="money !text-xl text-priceColor">
                            {itemPromotionPrice}ks
                          </span>
                        </span>
                        <span className="discounted-price !text-[16px]">
                          <s className="money ">{itemPrice}ks</s>
                        </span>
                      </>
                    ) : (
                      <span className="current-price">
                        <span className="money !text-xl text-priceColor">
                          {itemPrice}ks
                        </span>
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    {detailproduct.product_promotion_price !== 0 ? (
                      <>
                        <span className="current-price">
                          <span className="money !text-xl text-priceColor">
                            {detailproduct.product_promotion_price}ks
                          </span>
                        </span>
                        <span className="discounted-price !text-[16px]">
                          <s className="money ">
                            {detailproduct.product_price}ks
                          </s>
                        </span>
                      </>
                    ) : (
                      <span className="current-price">
                        <span className="money !text-xl text-priceColor">
                          {detailproduct.product_price}ks
                        </span>
                      </span>
                    )}
                  </>
                )}
              </div>
              <div className="review-div justify-between items-center">
                <p className="text-secondColor text-[14px] font-bold">
                  {t("ShopName")}:{" "}
                  <span
                    className="text-black font-normal   text-[12px] cursor-pointer hover:!underline"
                    onClick={() =>
                      navigate(`/StoreShop/${detailproduct.seller_id}`)
                    }
                  >
                    {detailproduct.shop_name}
                  </span>
                </p>
              </div>

              {sameColor.length > 0 && (
                <div className="product-spec-group">
                  <div className="text">
                    Colors : {activeColor && activeColor}
                  </div>
                  <div className="cursor-pointer mt-[10px] h-auto flex gap-x-2">
                    {sameColor.map((pItem, pIndex) => (
                      <div
                        className={
                          isActive == pItem.item_id
                            ? "flex justify-center items-center w-[55px] h-[55px] border-2 !border-red-600"
                            : "flex justify-center items-center w-[55px] h-[55px]"
                        }
                        key={pIndex}
                      >
                        <img
                          src={pItem.photo}
                          alt=""
                          key={pIndex}
                          onClick={() => choseColor(pItem.item_id, pItem.color)}
                          className="w-[50px] !h-[50px] object-cover"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {showSize.length > 0 && (
                <div className="product-spec-group mt-[15px]">
                  <div className="text">Sizes : {sizeAct && sizeAct}</div>
                  <div className="w-full flex gap-x-2">
                    {showSize?.map((sizeItem, sizeIndex) => (
                      <div
                        className={
                          sizeActive == sizeItem.item_id
                            ? "spec-div border-2 !border-red-600"
                            : "spec-div"
                        }
                        onClick={() =>
                          choseSize(sizeItem.item_id, sizeItem.size)
                        }
                      >
                        <div className="flex justify-center items-center">
                          {sizeItem.size}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div style={{ display: "flex" }}>
                <div className="product-spec-group my-[10px]">
                  <div className="w-[90%] h-auto flex justify-between items-center">
                    {itemDetail.stock ? (
                      <span>
                        {itemDetail.stock == 0 ? (
                          <p className="text-mainColor text-bold text-[16px] font-bold">
                            {t("OutOfStock")}
                          </p>
                        ) : (
                          <p className="text-mainColor text-bold text-[16px] font-bold">
                            {t("InStock")}
                          </p>
                        )}
                      </span>
                    ) : (
                      <span>
                        {detailproduct.stock == 0 ? (
                          <p className="text-mainColor text-bold text-[16px] font-bold">
                            {t("OutOfStock")}
                          </p>
                        ) : (
                          <p className="text-mainColor text-bold text-[16px] font-bold">
                            {t("InStock")}
                          </p>
                        )}
                      </span>
                    )}
                    {favProduct == 1 ? (
                      <Favorite
                        onClick={() => gofav(0)}
                        className="!text-4xl !text-[#fe7021]"
                      />
                    ) : (
                      <Favorite
                        onClick={() => gofav(1)}
                        className="!text-4xl !text-[#555]"
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="cart-btn-div gap-x-4">
                <div
                  onClick={removeCompareProduct}
                  className="add-compare-btn text-white !text-[16px] !font-bold"
                >
                  {t("Remove From Compare")}
                </div>
              </div>
            </div> */}
          </div>
          
          <div className="my-5 px-10">

          <div className="w-[100%] h-auto">
              <div className="product-title !text-[1.675rem] !text-black pr-8">
                {itemName ? itemName : detailproduct.product_name}
              </div>
              <div className="price mt-[15px]">
                {itemName ? (
                  <>
                    {itemPromotionPrice !== 0 ? (
                      <>
                        <span className="current-price">
                          <span className="money !text-xl text-priceColor">
                            {itemPromotionPrice}ks
                          </span>
                        </span>
                        <span className="discounted-price !text-[16px]">
                          <s className="money ">{itemPrice}ks</s>
                        </span>
                      </>
                    ) : (
                      <span className="current-price">
                        <span className="money !text-xl text-priceColor">
                          {itemPrice}ks
                        </span>
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    {detailproduct.product_promotion_price !== 0 ? (
                      <>
                        <span className="current-price">
                          <span className="money !text-xl text-priceColor">
                            {detailproduct.product_promotion_price}ks
                          </span>
                        </span>
                        <span className="discounted-price !text-[16px]">
                          <s className="money ">
                            {detailproduct.product_price}ks
                          </s>
                        </span>
                      </>
                    ) : (
                      <span className="current-price">
                        <span className="money !text-xl text-priceColor">
                          {detailproduct.product_price}ks
                        </span>
                      </span>
                    )}
                  </>
                )}
              </div>
              <div className="review-div justify-between items-center">
                <p className="text-secondColor text-[14px] font-bold">
                  {t("ShopName")}:{" "}
                  <span
                    className="text-black font-normal   text-[12px] cursor-pointer hover:!underline"
                    onClick={() =>
                      navigate(`/StoreShop/${detailproduct.seller_id}`)
                    }
                  >
                    {detailproduct.shop_name}
                  </span>
                </p>
              </div>

              {sameColor.length > 0 && (
                <div className="product-spec-group">
                  <div className="text">
                    Colors : {activeColor && activeColor}
                  </div>
                  <div className="cursor-pointer mt-[10px] h-auto flex gap-x-2">
                    {sameColor.map((pItem, pIndex) => (
                      <div
                        className={
                          isActive == pItem.item_id
                            ? "flex justify-center items-center w-[55px] h-[55px] border-2 !border-red-600"
                            : "flex justify-center items-center w-[55px] h-[55px]"
                        }
                        key={pIndex}
                      >
                        <img
                          src={pItem.photo}
                          alt=""
                          key={pIndex}
                          onClick={() => choseColor(pItem.item_id, pItem.color)}
                          className="w-[50px] !h-[50px] object-cover"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {showSize.length > 0 && (
                <div className="product-spec-group mt-[15px]">
                  <div className="text">Sizes : {sizeAct && sizeAct}</div>
                  <div className="w-full flex gap-x-2">
                    {showSize?.map((sizeItem, sizeIndex) => (
                      <div
                        className={
                          sizeActive == sizeItem.item_id
                            ? "spec-div border-2 !border-red-600"
                            : "spec-div"
                        }
                        onClick={() =>
                          choseSize(sizeItem.item_id, sizeItem.size)
                        }
                      >
                        <div className="flex justify-center items-center">
                          {sizeItem.size}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div style={{ display: "flex" }}>
                <div className="product-spec-group my-[10px]">
                  <div className="w-[90%] h-auto flex justify-between items-center">
                    {itemDetail.stock ? (
                      <span>
                        {itemDetail.stock == 0 ? (
                          <p className="text-mainColor text-bold text-[16px] font-bold">
                            {t("OutOfStock")}
                          </p>
                        ) : (
                          <p className="text-mainColor text-bold text-[16px] font-bold">
                            {t("InStock")}
                          </p>
                        )}
                      </span>
                    ) : (
                      <span>
                        {detailproduct.stock == 0 ? (
                          <p className="text-mainColor text-bold text-[16px] font-bold">
                            {t("OutOfStock")}
                          </p>
                        ) : (
                          <p className="text-mainColor text-bold text-[16px] font-bold">
                            {t("InStock")}
                          </p>
                        )}
                      </span>
                    )}
                    {favProduct == 1 ? (
                      <Favorite
                        onClick={() => gofav(0)}
                        className="!text-4xl !text-[#fe7021]"
                      />
                    ) : (
                      <Favorite
                        onClick={() => gofav(1)}
                        className="!text-4xl !text-[#555]"
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="cart-btn-div gap-x-4">
                <div
                  onClick={removeCompareProduct}
                  className="add-compare-btn text-white !text-[16px] !font-bold"
                >
                  {t("Remove From Compare")}
                </div>
              </div>
            </div>

            {shortdes && (
              <div className="w-full h-auto">
                <p className="text-2xl font-bold">{t("Summary")}</p>
                <ReactQuill
                  theme="bubble"
                  value={shortdes}
                  readOnly="true"
                  className="text-[16px]"
                />
              </div>
            )}
            {longdes && (
              <div className="desDetail">
                <span className="text-2xl font-bold">{t("ProductDetail")}</span>
                <ReactQuill theme="bubble" value={longdes} readOnly="true" />
              </div>
            )}
          </div>
        </div>
  );
};

export default memo(CompareProductItem);
