import React, { useState, useEffect } from "react";

import "../../mobile/accountSetting/LoginWithSms.scss";
import { Email } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import CutomErrorMessage from "../../components/commons/CutomErrorMessage";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { setAuthUser } from "../../reducers/user";
import { loginWithSMS, requestOTP } from "../../reducers/userActions";
import Loading from "../../components/commons/Loading";
import getCustomerCartItems from "../helper/CustomerCart";
import { useTranslation } from "react-i18next";

const codeSchema = Yup.object({
  code: Yup.number().required().label("SMS Verification Code"),
});
const LoginWithSms = () => {
  const { token } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authUser, error } = useSelector((state) => state.user);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  // useEffect(() => {
  //   if (token) {
  //     navigate("/");
  //   }
  //   window.scrollTo(0, 0);
  // }, [navigate, token]);
  const submitSmsLogin = async ({ code }) => {
    let userId = authUser ? (authUser.user ? authUser.user.id : null) : null;
    let requestObj = {
      method: "post",
      url: ApiCollections.loginWithSmsOTP,
      params: {
        user_id: userId,
        otp: code,
      },
    };

    setLoading(true);
    try {
      setErrors(null);
      const response = await dispatch(loginWithSMS(requestObj)).unwrap();
      setLoading(false);
      if (response.flag === false) {
        setErrors(response.message);
      } else if (
        response.data.code === 200 &&
        response.data.otp_status === true
      ) {
        toast.success(response.data.message);
        localStorage.setItem("userTokenID", response.data.data.user.id);
        dispatch(setAuthUser(response.data.data));
        getCustomerCartItems(response.data.data.user.id);
        localStorage.setItem("userTokenID", response.data.data.user.id);
        localStorage.setItem("userLoginStatus", "phone");
        getRegisterCoupon();
        navigate("/"); //redirect home
      } else if (
        response.data.code === 200 &&
        response.data.otp_status === false
      ) {
        toast.error(response.data.message);
      } else {
        toast.error("Something Wrong Login !");
      }
    } catch (err) {
      setLoading(false);
      toast.error(`Login failed: ${err.message}`);
    }
  };
  const requestSmsCode = async () => {
    let userId = authUser ? (authUser.user ? authUser.user.id : null) : null;
    let requestObj = {
      method: "get",
      url: ApiCollections.requestSmsCode,
      params: {
        user_id: userId,
      },
    };
    setLoading(true);
    try {
      setErrors(null);
      const response = await dispatch(requestOTP(requestObj)).unwrap();
      setLoading(false);
      if (response.flag === false) {
        setErrors(response.message);
      } else if (
        response.data.code === 200 &&
        response.data.otp_status === true
      ) {
        toast.success(response.data.message);
      } else if (
        response.data.code === 200 &&
        response.data.otp_status === false
      ) {
        toast.error(response.data.message);
      } else {
        toast.error("Something Wrong Request OTP !");
      }
    } catch (err) {
      setLoading(false);
      toast.error(`Request OTP failed: ${err.message}`);
    }
  };
  const getRegisterCoupon = async () => {
    let requestObj = {
      method: "post",
      url: ApiCollections.registerCoupon,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(requestObj);
    if (response.data.code === 200) {
    }
  };
  return (
    <div className="signup">
      {loading && <Loading open={loading} />}
      <ToastContainer autoClose={3000} />
      {error && toast.error(error)}
      {errors &&
        errors.map((error, index) => {
          toast.error(error);
        })}
      <div className="loginTitle">{t("LoginWithSms")}</div>
      <Formik
        initialValues={{ code: "" }}
        validationSchema={codeSchema}
        onSubmit={(values) => {
          submitSmsLogin(values);
        }}
      >
        {({ handleChange, handleSubmit, errors }) => (
          <Form>
            <div className="verCode">
              <div className="name">{t("VerificationCode")}</div>
              <div className="enterBox">
                <Email id="photo" />
                <input
                  type="number"
                  placeholder={t("pleaseVerificationCode")}
                  className="inputEnter"
                  name="code"
                  onChange={handleChange("code")}
                />
                {errors.code && <CutomErrorMessage error={errors.code} />}
              </div>
              <div className="request-sms-btn-container">
                <p>{t("Don'tReceiveSms")}</p>
                <Button variant="contained" onClick={requestSmsCode}>
                  {t("GetCodeViaSms")}
                </Button>
              </div>
            </div>
            <div className="btnLog" onClick={handleSubmit}>
              {t("LOGIN_BUTTON")}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginWithSms;
