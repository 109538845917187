import React, { useState, useEffect } from "react";
import "./SignUpCss.scss";
import Wrapper from "../Wrapper";
import Lottie from "lottie-react";
import signupCover from "../../assets/signupCover.json";
import {
  Phone,
  Lock,
  Visibility,
  VisibilityOff,
  Email,
} from "@mui/icons-material";
import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import CutomErrorMessage from "../../components/commons/CutomErrorMessage";
import ApiCollections from "../../services/ApiCollections";
import { setAuthUser } from "../../reducers/user";
import { passwordReset } from "../../reducers/userActions";
import Loading from "../../components/commons/Loading";
import { useTranslation } from "react-i18next";

const userInfoSchema = Yup.object({
  password: Yup.string().required().min(6).matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,"At least one number, one samll and capital letter").label("New Password"),
  confirm_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

const ResetPassword = () => {
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealCom, setIsRevealCom] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const { token, authUser, error } = useSelector((state) => state.user);
  const { t } = useTranslation();

  let phone_number = authUser
    ? authUser.user
      ? authUser.user.phone_number
      : ""
    : "";
  useEffect(() => {
    if (token !== null) {
      navigate("/");
    }
  }, [navigate, token]);

  const submitPasswordReset = async ({ password, confirm_password }) => {
    let requestObj = {
      method: "post",
      url: ApiCollections.resetPassword,
      params: {
        phone: phone_number,
        password,
        confirm_password,
      },
    };
    setLoading(true);
    try {
      setErrors(null);
      const response = await dispatch(passwordReset(requestObj)).unwrap();
      setLoading(false);
      if (response.flag === false) {
        setErrors(response.message);
      } else if (response.data.code === 200) {
        toast.success(response.data.message);
        //set aut user data to state
        dispatch(setAuthUser(response.data.data));
        localStorage.setItem("userTokenID", response.data.data.user.id);
        localStorage.setItem("userLoginStatus", "phone");
        navigate("/");
      } else {
        toast.error("Something Wrong on New Password Set!");
      }
    } catch (err) {
      setLoading(false);
      toast.error(`Request failed: ${err.message}`);
    }
  };

  return (
    <Wrapper>
      {loading && (
        <div className="w-full h-[100vh] flex justify-center items-center bg-white">
          <Loading open={loading} />
        </div>
      )}
      <Container>
        <div className="SignUpDiv">
          <ToastContainer autoClose={3000} />
          {error && toast.error(error)}
          {errors &&
            errors.map((error, index) => {
              toast.error(error);
            })}
          <div className="leftBox">
            <div className="loginTitle">{t("RESET_PASSWORD")}</div>

            <Formik
              initialValues={{ password: "", confirm_password: "" }}
              validationSchema={userInfoSchema}
              onSubmit={(values) => {
                submitPasswordReset(values);
              }}
            >
              {({ handleChange, handleSubmit, errors }) => (
                <Form>
                  <div className="userBox">
                    <div className="name">{t("phoneNumber")}</div>
                    <div className="enterBox">
                      <Phone id="photo" />
                      <input
                        type="text"
                        value={phone_number}
                        placeholder={t("pleasePhone")}
                        className="inputEnter"
                      />
                    </div>
                  </div>
                  <div className="passwordBox">
                    <div className="name">{t("NewPassword")}</div>
                    <div className="enterBox">
                      <Lock id="photo" />
                      <input
                        placeholder={t("pleaseNewPassword")}
                        className="inputEnter"
                        type={isRevealPwd ? "text" : "password"}
                        name="password"
                        onChange={handleChange("password")}
                      />
                      {isRevealPwd ? (
                        <Visibility
                          id="eyepho"
                          onClick={() =>
                            setIsRevealPwd((prevState) => !prevState)
                          }
                        />
                      ) : (
                        <VisibilityOff
                          id="eyepho"
                          onClick={() =>
                            setIsRevealPwd((prevState) => !prevState)
                          }
                        />
                      )}
                    </div>
                    {errors.password && (
                      <CutomErrorMessage error={errors.password} />
                    )}
                  </div>
                  <div className="passwordBox">
                    <div className="name">{t("ConfirmPassword")}</div>
                    <div className="enterBox">
                      <Lock id="photo" />
                      <input
                        placeholder={t("pleaseConfirmPassword")}
                        className="inputEnter"
                        type={isRevealCom ? "text" : "password"}
                        name="confirm_password"
                        onChange={handleChange("confirm_password")}
                      />
                      {isRevealCom ? (
                        <Visibility
                          id="eyepho"
                          onClick={() =>
                            setIsRevealCom((prevState) => !prevState)
                          }
                        />
                      ) : (
                        <VisibilityOff
                          id="eyepho"
                          onClick={() =>
                            setIsRevealCom((prevState) => !prevState)
                          }
                        />
                      )}
                    </div>
                    {errors.confirm_password && (
                      <CutomErrorMessage error={errors.confirm_password} />
                    )}
                  </div>
                  <div className="btnLog" onClick={handleSubmit}>
                    {t("Reset")}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="rightBox">
            <Lottie animationData={signupCover} loop={true} id="logCov" />
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default ResetPassword;
