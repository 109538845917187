import React, { useState, useEffect } from "react";
import "./SignUpCss.scss";
import Wrapper from "../Wrapper";
import Lottie from "lottie-react";
import signupCover from "../../assets/signupCover.json";
import {
  Phone,
  Lock,
  Visibility,
  VisibilityOff,
  Email,
} from "@mui/icons-material";
import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import CutomErrorMessage from "../../components/commons/CutomErrorMessage";
import ApiCollections from "../../services/ApiCollections";
import { setAuthUser } from "../../reducers/user";
import { registerWithPhone } from "../../reducers/userActions";
import Loading from "../../components/commons/Loading";
import { useTranslation } from "react-i18next";

const userInfoSchema = Yup.object({
  phone: Yup.string().required().label("Phone Number"),
  password: Yup.string().required().min(6).matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,"At least one number, one samll and capital letter").label("Password"),
  confirm_password: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const SignUp = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.user);
  const [errors, setErrors] = useState(null);
  const { t } = useTranslation();

  const gotoLogin = () => {
    navigate("/login-with-phone");
  };

  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealCom, setIsRevealCom] = useState(false);
  const dispatch = useDispatch();
  const submitSignUp = async ({ phone, password, confirm_password }) => {
    let requestObj = {
      method: "post",
      url: ApiCollections.registerWithPhone,
      params: {
        phone,
        password,
        confirm_password,
      },
    };

    setLoading(true);
    try {
      setErrors(null);
      const response = await dispatch(registerWithPhone(requestObj)).unwrap();
      setLoading(false);
      if (response.flag === false) {
        setErrors([response.message[0].data.message]);
      } else if (
        response.data.code === 201 &&
        response.data.otp_status === true
      ) {
        toast.success(response.data.message);
        //set aut user data to state
        dispatch(setAuthUser(response.data.data));
        navigate("/Login-Sms"); // step two for login (that must enter sms code)
      } else {
        toast.error("Something Wrong on Register!");
      }
    } catch (err) {
      toast.error(`Request failed: ${err.message}`);
    }
  };

  return (
    <Wrapper>
      {loading && (
        <div className="w-full h-[100vh] flex justify-center items-center bg-white">
          <Loading open={loading} />
        </div>
      )}
      <Container>
        <div className="SignUpDiv">
          <ToastContainer autoClose={3000} />
          {error && toast.error(error)}
          {errors &&
            errors.map((error, index) => {
              toast.error(error);
            })}
          <div className="leftBox">
            <div className="loginTitle">{t("SIGNUP")}</div>

            <Formik
              initialValues={{ phone: "", password: "", confirm_password: "" }}
              validationSchema={userInfoSchema}
              onSubmit={(values) => {
                submitSignUp(values);
              }}
            >
              {({ handleChange, handleSubmit, errors }) => (
                <Form>
                  <div className="userBox">
                    <div className="name">{t("phoneNumber")}</div>
                    <div className="enterBox">
                      <Phone id="photo" />
                      <input
                        type="text"
                        placeholder={t("pleasePhone")}
                        className="inputEnter"
                        name="phone"
                        onChange={handleChange("phone")}
                      />
                    </div>
                    {errors.phone && <CutomErrorMessage error={errors.phone} />}
                  </div>
                  <div className="passwordBox">
                    <div className="name">{t("PASSWORD")}</div>
                    <div className="enterBox">
                      <Lock id="photo" />
                      <input
                        placeholder={t("pleasePassword")}
                        className="inputEnter"
                        type={isRevealPwd ? "text" : "password"}
                        name="password"
                        onChange={handleChange("password")}
                      />
                      {isRevealPwd ? (
                        <Visibility
                          id="eyepho"
                          onClick={() =>
                            setIsRevealPwd((prevState) => !prevState)
                          }
                        />
                      ) : (
                        <VisibilityOff
                          id="eyepho"
                          onClick={() =>
                            setIsRevealPwd((prevState) => !prevState)
                          }
                        />
                      )}
                    </div>
                    {errors.password && (
                      <CutomErrorMessage error={errors.password} />
                    )}
                  </div>
                  <div className="passwordBox">
                    <div className="name">{t("ConfirmPassword")}</div>
                    <div className="enterBox">
                      <Lock id="photo" />
                      <input
                        placeholder={t("pleasePassword")}
                        className="inputEnter"
                        type={isRevealCom ? "text" : "password"}
                        name="confirm_password"
                        onChange={handleChange("confirm_password")}
                      />
                      {isRevealCom ? (
                        <Visibility
                          id="eyepho"
                          onClick={() =>
                            setIsRevealCom((prevState) => !prevState)
                          }
                        />
                      ) : (
                        <VisibilityOff
                          id="eyepho"
                          onClick={() =>
                            setIsRevealCom((prevState) => !prevState)
                          }
                        />
                      )}
                    </div>
                    {errors.confirm_password && (
                      <CutomErrorMessage error={errors.confirm_password} />
                    )}
                  </div>
                  <div className="btnLog" onClick={handleSubmit}>
                    {t("SIGNUP_BUTTON")}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="rightBox">
            <Lottie animationData={signupCover} loop={true} id="logCov" />
            <div className="phoneDiv" onClick={gotoLogin}>
              {t("ALREADY_HAVE_ACCOUNT")}
              <span>&nbsp;{t("LOGIN")}</span>
            </div>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default SignUp;
