import React, { memo, useEffect, useState } from "react";
import Wrapper from "../../Wrapper";
import { Container, ListItemButton, ListItemText } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./StorePage.scss";
import ApiCollections from "../../../services/ApiCollections";
import { ApiRequest } from "../../../services/common/ApiRequest";
import Loading from "../../../components/commons/Loading";
import ListItem from '@mui/material/ListItem';

const data =     {
  "id": 4,
  "name": "Test User",
  "phone_numbers": "09776995184",
  "region_id": 12,
  "region_name": "Yangon",
  "is_entire_region": 0,
  "city_id": 255,
  "city_name": "Mingala Taungnyunt",
  "marketplace_service_ids": "[\"6\",\"4\",\"2\",\"5\",\"3\"]",
  "merketplace_services": [
    {
      "id": 2,
      "service_type": "Transportation EN"
    },
    {
      "id": 3,
      "service_type": "Relaxation EN"
    },
    {
      "id": 4,
      "service_type": "Vue EN"
    },
    {
      "id": 5,
      "service_type": "Laravel EN"
    },
    {
      "id": 6,
      "service_type": "React EN"
    }
  ],
  "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  "address": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  "photo_url": "https://test-backend.mgdmall.com.mm/api/show-file/25a1c14d-95bf-4670-99a7-ff9ec9ce3655",
  "created_at": "2025-02-27 22:01:37",
  "merketplace_services_arr": [
    "Transportation EN",
    "Relaxation EN",
    "Vue EN",
    "Laravel EN",
    "React EN"
  ]
}
const ServiceShopDetail = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll(0, 0);
    getStoreShop();
  }, []);

  const { shopId } = useParams();

  const [shopDetail, setShopDetail] = useState(data);
  const [loading, setLoading] = useState(false);

  const getStoreShop = async () => {
    setLoading(true);
    let requestObj = {
      method: "post",
      url: ApiCollections.serviceShops,
      params: {
        id: shopId,
      },
    };
    let response = await ApiRequest(requestObj);
    setLoading(false);

    response = response.data;
    // if (response?.data) {
    //   setShopDetail(response?.data);
    // }
  };

  return (
    <Wrapper>
      {loading && <Loading open={loading} />}
      <Container>
        <div className="w-full h-auto ">
          <div className="w-full h-[200px] mt-[70px] mb-[20px]">
            <img src={shopDetail?.photo_url} className="w-full h-full" />
          </div>
          <div className="w-full h-auto flex justify-between">
            <div className="w-[80%] flex">
              <div className="w-[70px] h-[70px]">
                <img
                  src={shopDetail?.photo_url}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex flex-col justify-center pl-2">
                <p className="text-xl">
                  {shopDetail?.is_entire_region ? shopDetail?.region_name : shopDetail?.city_name}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full h-auto">
            <div className="w-full flex border-b border-black pt-14 pb-2">
              <div>
                <p
                  className="text-[22px] text-mainColor font-bold
                "
                >
                  {t("Service")}
                </p>
              </div>
            </div>
            <div className="w-full h-auto grid grid-cols-3 gap-x-4 mt-8">
              {
                shopDetail?.merketplace_services.map(service => <>
                  <ListItem>
                    <ListItemButton>
                      <ListItemText primary={service?.service_type} />
                    </ListItemButton>
                  </ListItem>
                 </>)
              }
            </div>
          </div>
          <div className="w-full h-auto">
            <div className="w-full flex border-b border-black pt-14 pb-2">
              <div>
                <p
                  className="text-[22px] text-mainColor font-bold
                "
                >
                  {t("Service Area")}
                </p>
              </div>
            </div>
            <div className="w-full h-auto grid grid-cols-3 gap-x-4 mt-8">
              {
                shopDetail?.merketplace_services_arr.map(area => <>
                  <ListItem>
                    <ListItemButton>
                      <ListItemText primary={area} />
                    </ListItemButton>
                  </ListItem>
                 </>)
              }
            </div>
          </div>
          <div className="w-full h-auto">
            <div className="w-full flex border-b border-black pt-14 pb-2">
              <div>
                <p
                  className="text-[22px] text-mainColor font-bold
                "
                >
                  {t("Address")}
                </p>
              </div>
            </div>
            <div className="w-full h-auto grid grid-cols-3 gap-x-4 mt-8">
              {
                shopDetail?.address
              }
            </div>
          </div>
          <div className="w-full h-auto">
            <div className="w-full flex border-b border-black pt-14 pb-2">
              <div>
                <p
                  className="text-[22px] text-mainColor font-bold
                "
                >
                  {t("Description")}
                </p>
              </div>
            </div>
            <div className="w-full h-auto grid grid-cols-3 gap-x-4 mt-8">
              {
                shopDetail?.description
              }
            </div>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default memo(ServiceShopDetail);
