import { Container } from "@mui/material";
import React, { useEffect,memo } from "react";
import Wrapper from "../../Wrapper";
import ServiceShopCard from "./ServiceShopCard";

const ServiceShopList = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Wrapper>
      <Container style={{ marginTop: "50px" }}>
        <ServiceShopCard />
      </Container>
    </Wrapper>
  );
};

export default memo(ServiceShopList);
