import React, { useState, useEffect } from "react";
import "../../mobile/product/ProductDetailCss.scss";
import "../../mobile/product/PopupCss.scss";
import Carousel from "react-material-ui-carousel";
import { useNavigate, useParams } from "react-router-dom";
import { Drawer, Box, Modal } from "@mui/material";
import {
  Star,
  KeyboardArrowRight,
  HighlightOff,
  ChevronRight,
  ArrowCircleLeft,
  Favorite,
  ContactSupport,
  QuestionAnswer,
  SmartDisplay,
  CompareArrows
} from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import { addToCartProductAsync } from "../../reducers/cartActions";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  getTotalAmount,
  getCartCount,
} from "../../reducers/cartSlice";
import { string } from "prop-types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation } from "react-router-dom";
import YoutubeEmbed from "../../components/commons/YoutubeEmbed";
import "../../components/videoCss.scss";
// swiper package
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { updatecompareProducts } from "../../reducers/appState";

const ProductDetail = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { authUser } = useSelector((state) => state.user);
  const { compareProducts } = useSelector((state) => state.appState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gobackhome = () => {
    navigate(-1);
  };

  let { productId, itemId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    sessionStorage.removeItem("itemid");
    sessionStorage.removeItem("productid");
    sessionStorage.removeItem("simpleQTY");
    getProductDetail();
    getAnswer();
    getreviewProduct();
  }, []);

  const [loading, setLoading] = useState(false);

  const [detailproduct, setProductDetail] = useState([]);
  const [videoLink, setvideoLink] = useState([]);
  const [VideoIcon, setVideoIcon] = useState([]);
  const [sameColor, setSameColor] = useState([]);
  const [longdes, setLong] = useState("");
  const [shortdes, setShort] = useState("");
  const [serlerID, setSellerID] = useState([]);
  const getProductDetail = async () => {
    setLoading(true);
    // let obj = {
    //   method: "get",
    //   url: ApiCollections.productDetailApi,
    //   params: {
    //     id: productId,
    //     customer_id: localStorage.getItem("userTokenID"),
    //   },
    // };

    let obj = {
      method: "get",
      url: ApiCollections.productDetailApi,
      params: {
        id: productId,
        customer_id: localStorage.getItem("userTokenID"),
      },
    };
    if (itemId) {
      let itemIdObj = { item_id: itemId };
      Object.assign(obj.params, itemIdObj);
    }

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setProductDetail(response.data);
      setSameColor([
        ...new Map(
          response.data.items.map((item) => [item["color"], item])
        ).values(),
      ]);
      getSameStore(response.data.category_id);
      setvideoLink(response.data.product_link.substring(17, 28));
      setVideoIcon(response.data.product_file[0].photo);
      setSellerID(response.data.seller_id);
      setFavProduct(response.data.favourite_status);
      setLong(response.data.product_longdescription);
      setShort(response.data.product_description);
    }
  };

  const [showSize, setSize] = useState([]);
  const [isActive, setActive] = useState();
  const [activeColor, setActiveColor] = useState();
  const choseColor = async (selectID, selectColor) => {
    setActive(selectID);
    setActiveColor(selectColor);
    let obj = {
      method: "get",
      url: ApiCollections.sizeApi,
      params: {
        id: productId,
        color: selectColor,
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setSize(response.data);
      setSizeAct("");
      setSizeActive("");
    }
  };

  const [itemproduct, setItemDetail] = useState([]);
  const [sizeActive, setSizeActive] = useState();
  const [sizeAct, setSizeAct] = useState();
  const choseSize = async (sizeID, sizeName) => {
    setSizeActive(sizeID);
    setSizeAct(sizeName);
    let obj = {
      method: "get",
      url: ApiCollections.itemDetailApi,
      params: {
        id: sizeID,
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setItemDetail(response.data);
    }
  };

  const [favProduct, setFavProduct] = useState("0");
  const gofav = async (index) => {
    if (localStorage.getItem("userTokenID") != null) {
      setFavProduct(index);
      let requestObj = {
        method: "post",
        url: ApiCollections.favStatus,
        params: {
          customer_id: localStorage.getItem("userTokenID"),
          favourite_status: index,
          product_id: productId,
        },
      };
      let response = await ApiRequest(requestObj);

      if (response.data.code === 200) {
        toast.success(response.data.message);
      } else {
        toast.error("Something Wrong when try to follow!");
      }
    } else {
      if (location && location.pathname)
        sessionStorage.setItem("redirectUrl", location.pathname);
      navigate("/login-with-phone");
    }
  };

  const [sameStore, setSameStore] = useState([]);
  const [storepageCount, setStorePageCount] = useState(0);
  const getSameStore = async (categoryID) => {
    let obj = {
      method: "get",
      url: ApiCollections.sameStore,
      params: {
        id: productId,
        categoryid: categoryID,
        page: 1,
        last: storepageCount,
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      setSameStore([...sameStore, ...response.data]);
      setStorePageCount(response.last_page);
    }
  };

  const gotoproduct = (product_id, item_status, item_id) => {
    if (item_status != 0) {
      navigate(`/products/product-detail/${product_id}/${item_id}`);
      window.location.reload();
      window.scrollTo(0, 0);
      document.getElementById("idTop");
    } else {
      navigate(`/products/product-detail/${product_id}`);
      window.location.reload();
      window.scrollTo(0, 0);
      document.getElementById("idTop");
    }
  };
  const gotocomment = () => {
    navigate(`/Comment/${productId}`);
  };
  const gotoqa = () => {
    navigate(`/QuestionAndAnswer/${productId}/${serlerID}`);
  };
  const [showAns, setAns] = useState([]);
  const [showCount, setCount] = useState([]);
  const getAnswer = async () => {
    let requestObj = {
      method: "post",
      url: ApiCollections.giveAnswer,
      params: {
        product_id: productId,
      },
    };

    let response = await ApiRequest(requestObj);
    if (response.data.code === 200) {
      setAns(response.data.data);
      setCount(response.data.count);
    }
  };

  const [reviewProduct, setreviewProduct] = useState([]);
  const [reviewCount, setreviewCount] = useState([]);
  const getreviewProduct = async () => {
    let requestObj = {
      method: "post",
      url: ApiCollections.productReviewApi,
      params: {
        product_id: productId,
      },
    };

    let response = await ApiRequest(requestObj);
    if (response.data.code === 200) {
      setreviewProduct(response.data.data);
      setreviewCount(response.data.count);
    }
  };

  const gotoCheckout = () => {
    sessionStorage.setItem("simpleQTY", quantity);
    if (!sizeActive && !sizeAct && detailproduct.item_status == 1) {
      toast.error("Select the color and size", {
        className: "toastColor",
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }

    if (
      detailproduct.stock < quantity ||
      detailproduct.stock == 0 ||
      itemproduct.stock < quantity ||
      itemproduct.stock == 0
    ) {
      toast.error(
        "Your purchased stock is greater than the number of items in the store",
        {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        }
      );
    } else {
      if (sizeActive && sizeAct && detailproduct.item_status == 1) {
        navigate("/CheckOut");
        sessionStorage.setItem("itemid", sizeActive);
      }
      if (detailproduct.item_status == 0) {
        navigate("/CheckOut");
        sessionStorage.setItem("productid", productId);
      }
    }
  };

  const addToCartProduct = async () => {
    let customerId = authUser
      ? authUser.user
        ? authUser.user.id
        : null
      : null;
    if (customerId) {
      if (detailproduct.item_status == 0) {
        if (detailproduct.stock < quantity || detailproduct.stock == 0) {
          toast.error(
            "Your purchased stock is greater than the number of items in the store",
            {
              className: "toastColor",
              draggable: true,
              position: toast.POSITION.TOP_CENTER,
            }
          );
        } else {
          let data = {
            customer_id: customerId,
            product_id: detailproduct.product_id,
            title: detailproduct.product_name,
            image: detailproduct.product_photo,
            price: detailproduct.product_price,
            promotion_price: detailproduct.product_promotion_price,
            size: sizeActive,
            color: activeColor,
            quantity,
            type: "product",
            seller_id: detailproduct.seller_id,
          };
          let obj = {
            method: "post",
            url: ApiCollections.addToCartProductServer,
            params: data,
          };
          setLoading(true);
          const response = await dispatch(addToCartProductAsync(obj)).unwrap();
          setLoading(false);
          if (response.flag === false) {
            toast.error(`Error on Add To Cart`);
          } else if (response.data.code === 200) {
            dispatch(addToCart(response.data.data));
            dispatch(getTotalAmount());
            dispatch(getCartCount());
            toast.success("Product Add to Cart!");
          } else if (response.data.code === 400) {
            toast.error(response.data.data.message);
          } else {
            toast.error("Something Wrong when try to add product to cart!");
          }
        }
        // dispatch(
        //   addToCart({
        //     id: detailproduct.product_id,
        //     title: detailproduct.product_name,
        //     image: detailproduct.product_photo,
        //     price: detailproduct.product_price,
        //     promotionPrice: detailproduct.product_promotion_price,
        //     seller_id: detailproduct.seller_id,
        //     size: sizeActive,
        //     color: activeColor,
        //     quantity,
        //     type: "product",
        //   })
        // );
        // dispatch(getTotalAmount());
        // dispatch(getCartCount());
        // toast.success("Product Add to Cart!");
      } else {
        if (!sizeActive && !sizeAct && detailproduct.item_status == 1) {
          toast.info("Select the color and size", {
            className: "toastColor",
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          if (itemproduct.stock < quantity || itemproduct.stock == 0) {
            toast.error(
              "Your purchased stock is greater than the number of items in the store",
              {
                className: "toastColor",
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              }
            );
          } else {
            let data = {
              customer_id: customerId,
              product_id: itemproduct.item_id,
              title: itemproduct.product_name,
              image: itemproduct.product_photo,
              price: itemproduct.price,
              promotion_price: itemproduct.product_promotion_price,
              size: sizeActive,
              color: activeColor,
              quantity,
              type: "item",
              seller_id: itemproduct.seller_id,
            };
            let obj = {
              method: "post",
              url: ApiCollections.addToCartProductServer,
              params: data,
            };
            setLoading(true);
            const response = await dispatch(
              addToCartProductAsync(obj)
            ).unwrap();
            setLoading(false);
            if (response.flag === false) {
              toast.error(`Error on Add To Cart`);
            } else if (response.data.code === 200) {
              dispatch(addToCart(response.data.data));
              dispatch(getTotalAmount());
              dispatch(getCartCount());
              toast.success("Product Add to Cart!");
            } else if (response.data.code === 400) {
              toast.error(response.data.data.message);
            } else {
              toast.error("Something Wrong when try to add product to cart!");
            }
          }
          // dispatch(
          //   addToCart({
          //     id: itemproduct.item_id,
          //     title: itemproduct.product_name,
          //     image: itemproduct.product_photo,
          //     price: itemproduct.price,
          //     promotionPrice: itemproduct.product_promotion_price,
          //     seller_id: itemproduct.seller_id,
          //     size: sizeActive,
          //     color: activeColor,
          //     quantity,
          //     type: "item",
          //   })
          // );
          // dispatch(getTotalAmount());
          // dispatch(getCartCount());
          // toast.success("Product Add to Cart!");
        }
      }
    } else {
      toast.error(t("LOGIN_MAKE_ADD_TO_CAR"));
      setTimeout(() => {
        navigate("/login-with-phone");
      }, 5000);
    }
  };

  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, bottom: open });
  };

  const [quantity, setQuantity] = useState(1);
  const changeQuantity = (value) => {
    value === "decrease" && quantity > 1 && setQuantity(quantity - 1);
    value === "increase" && setQuantity(quantity + 1);
  };
  const goShop = (id) => {
    navigate(`/StoreShop/${id}`);
  };

  const [openVideo, setOpenVideo] = React.useState(false);
  const handleOpenVideo = () => setOpenVideo(true);
  const handleCloseVideo = () => setOpenVideo(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "2px solid #fff",
    boxShadow: 24,
    p: 2,
  };

    const addCompareProduct = async () => {
      let productId = detailproduct?.product_id;
      if(compareProducts && compareProducts?.length) {
        if(compareProducts.find(product => product.product_id == productId)) {
          let dataCompare = compareProducts.filter(productID => productID.product_id !== productId);
          dispatch(updatecompareProducts(dataCompare));
  
          toast.error("Product Remove from Compare!", {
            className: "toastColor",
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        }else{
          if(compareProducts.length > 2) {
            toast.error("3 Product only can add !", {
              className: "toastColor",
              draggable: true,
              position: toast.POSITION.TOP_CENTER,
            });
          }else{
            let dataCompare = [...compareProducts,detailproduct];
            // dataCompare.push(data);
            dispatch(updatecompareProducts(dataCompare));
  
            toast.success("Product Add to Compare!", {
              className: "toastColor",
              draggable: true,
              position: toast.POSITION.TOP_CENTER,
            });
            
          }
  
        }
      }else {
        let dataCompare = [detailproduct];
        dispatch(updatecompareProducts(dataCompare));
        toast.success("Product Add to Compare!", {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
  
      }
    };

  return (
    <div>
      {loading && (
        <div className="w-full h-[100vh] flex justify-center items-center bg[#f1f1f1]">
          <Loading open={loading} />
        </div>
      )}
      <ToastContainer autoClose={3000} />
        <div className="productDetailDiv" id="idTop">
        <ArrowCircleLeft id="backarr" onClick={() => gobackhome()} />
        <CompareArrows id="compareBtn" onClick={addCompareProduct} />
        <div>
          {itemproduct.product_file ? (
            <div className="flvd">
              <Swiper className="mySwiper">
                {VideoIcon && videoLink && (
                  <SwiperSlide>
                    <div className="proPhoto" onClick={handleOpenVideo}>
                      <img src={VideoIcon} className="proImg" />
                      <div className="currentimg">
                        1/{itemproduct.product_file.length}
                      </div>
                      <div className="videoDiv">
                        <SmartDisplay className="vdicon" />
                      </div>
                    </div>
                  </SwiperSlide>
                )}
                {itemproduct.product_file &&
                  itemproduct.product_file.map((item, index) => (
                    <SwiperSlide>
                      <div key={index} className="proPhoto">
                        <img src={item.photo} className="proImg" />
                        <div className="currentimg">
                          {index + 1}/{itemproduct.product_file.length}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          ) : (
            <div className="flvd">
              <Swiper className="mySwiper">
                {VideoIcon && videoLink && (
                  <SwiperSlide>
                    <div className="proPhoto" onClick={handleOpenVideo}>
                      <img src={VideoIcon} className="proImg" />
                      {detailproduct.product_file && (
                        <div className="currentimg">
                          1/{detailproduct.product_file.length}
                        </div>
                      )}

                      <div className="videoDiv">
                        <SmartDisplay className="vdicon" />
                      </div>
                    </div>
                  </SwiperSlide>
                )}
                {detailproduct.product_file &&
                  detailproduct.product_file.map((item, index) => (
                    <SwiperSlide>
                      <div key={index} className="proPhoto">
                        <img src={item.photo} className="proImg" />
                        <div className="currentimg">
                          {index + 1}/{detailproduct.product_file.length}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          )}

          <Modal
            open={openVideo}
            onClose={handleCloseVideo}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <YoutubeEmbed embedId={videoLink} />
            </Box>
          </Modal>

          <div className="describe">
            {itemproduct.product_name ? (
              <p className="descriptionFn">{itemproduct.product_name}</p>
            ) : (
              <p className="descriptionFn">{detailproduct.product_name}</p>
            )}
            {itemproduct.price ? (
              <p className="price">
                {itemproduct.product_promotion_price !== 0 ? (
                  <span className="ori">
                    {itemproduct.product_promotion_price} ks &nbsp;&nbsp;
                    <s className="sdel">{itemproduct.price}ks</s>
                  </span>
                ) : (
                  <span className="ori">{itemproduct.price} ks</span>
                )}
              </p>
            ) : (
              <p className="price">
                {detailproduct.product_promotion_price !== 0 ? (
                  <span className="ori">
                    {detailproduct.product_promotion_price} ks &nbsp;&nbsp;
                    <s className="sdel">{detailproduct.product_price}ks</s>
                  </span>
                ) : (
                  <span className="ori">{detailproduct.product_price} ks</span>
                )}
              </p>
            )}
            <div className="rating">
              {detailproduct.star_count != null && (
                <div>
                  {[...Array(detailproduct.star_count)].map((i) => (
                    <span key={i}>
                      <Star fontSize="small" id="onestar" />
                    </span>
                  ))}
                  {[...Array(5 - detailproduct.star_count)].map((i) => (
                    <span key={i}>
                      <Star fontSize="small" id="notstar" />
                    </span>
                  ))}
                  ({detailproduct.person_count})
                </div>
              )}
              {favProduct == 1 ? (
                <Favorite id="actfav" onClick={() => gofav(0)} />
              ) : (
                <Favorite id="fav" onClick={() => gofav(1)} />
              )}
            </div>
            {detailproduct.brand_id != 1 ? (
              <div className="brandName">
                <span className="afix">{t("AskQuestion")} :</span>
                &nbsp;
                <span className="asin">{detailproduct.brand_name}</span>
              </div>
            ) : (
              <div></div>
            )}

            <div className="shopName">
              <span
                className="afix"
                onClick={() => goShop(detailproduct.seller_id)}
              >
                {t("ShopName")} :
              </span>
              &nbsp;
              <span
                className="asin"
                onClick={() => goShop(detailproduct.seller_id)}
              >
                {detailproduct.shop_name}
              </span>
              {itemproduct.stock ? (
                <span>
                  {itemproduct.stock == 0 ? (
                    <span className="outStock">{t("OutOfStock")}</span>
                  ) : (
                    <span className="outStock">{t("InStock")}</span>
                  )}
                </span>
              ) : (
                <span>
                  {detailproduct.stock == 0 ? (
                    <span className="outStock">{t("OutOfStock")}</span>
                  ) : (
                    <span className="outStock">{t("InStock")}</span>
                  )}
                </span>
              )}
            </div>
          </div>

          <div>
            <React.Fragment key={"bottom"}>
              <div className="specification" onClick={toggleDrawer(true)}>
                <div className="selectColor">
                  <div className="title">
                    {t("ChooseQuantity")}
                    {/* <span className="name">&nbsp;Black</span> */}
                    <KeyboardArrowRight id="btnRight" />
                  </div>
                  {/* <div className="specImage">
                                            {   detailproduct.items &&
                                                detailproduct.items.slice(0,4).map((colorItem,colorIndex) => (     
                                                    <img 
                                                        src={ colorItem.photo} 
                                                        key={colorIndex} 
                                                        className="showImage"/>
                                                )) 
                                            }
                                        </div> */}
                </div>
              </div>
              <Drawer
                anchor={"bottom"}
                open={state["bottom"]}
                onClose={toggleDrawer(false)}
              >
                <Box sx={{ width: "auto" }} role="presentation">
                  <div className="popbox">
                    <div className="showProduct">
                      {itemproduct.product_photo ? (
                        <img src={itemproduct.product_photo} />
                      ) : (
                        <img src={detailproduct.product_photo} />
                      )}

                      <div className="detail">
                        {itemproduct.price ? (
                          <div className="priceBox">
                            {itemproduct.product_promotion_price !== 0 ? (
                              <div>
                                <span className="price">
                                  {itemproduct.product_promotion_price} ks
                                </span>
                                <span className="disPrice">
                                  {itemproduct.price}ks
                                </span>
                              </div>
                            ) : (
                              <div>
                                <span className="price">
                                  {itemproduct.price} ks
                                </span>
                              </div>
                            )}
                            {/* <span className="price">
                                                            {detailproduct.product_price || itemproduct.price} ks</span> */}
                            {/* <span className="disPrice">1500 ks</span> */}
                          </div>
                        ) : (
                          <div className="priceBox">
                            {detailproduct.product_promotion_price !== 0 ? (
                              <div>
                                <span className="price">
                                  {detailproduct.product_promotion_price} ks
                                </span>
                                <span className="disPrice">
                                  {detailproduct.product_price}ks
                                </span>
                              </div>
                            ) : (
                              <div>
                                <span className="price">
                                  {detailproduct.product_price} ks
                                </span>
                              </div>
                            )}
                            {/* <span className="price">
                                                            {detailproduct.product_price || itemproduct.price} ks</span> */}
                            {/* <span className="disPrice">1500 ks</span> */}
                          </div>
                        )}

                        {activeColor ? (
                          <div className="color">{activeColor}</div>
                        ) : (
                          <div className="color">{itemproduct.color}</div>
                        )}
                        <div className="size">{sizeAct}</div>
                      </div>
                      <HighlightOff id="cross" onClick={toggleDrawer(false)} />
                    </div>
                    <div className="chosespecification">
                      {detailproduct.item_status == 1 && (
                        <div className="selectColor">
                          <div className="title">{t("Color")}</div>
                          <div className="specImage">
                            {sameColor &&
                              sameColor.map((colorItem, colorIndex) => (
                                <img
                                  src={colorItem.photo}
                                  key={colorIndex}
                                  onClick={() =>
                                    choseColor(
                                      colorItem.item_id,
                                      colorItem.color
                                    )
                                  }
                                  className={
                                    isActive === colorItem.item_id
                                      ? "selActive showImage"
                                      : "showImage"
                                  }
                                />
                              ))}
                          </div>
                        </div>
                      )}
                      {showSize.length > 0 && (
                        <div className="siteChat">
                          <div className="title">
                            {t("Size")} :&nbsp;&nbsp;
                            {showSize.map((sizeItem, sizeIndex) => (
                              <span
                                key={sizeIndex}
                                onClick={() =>
                                  choseSize(sizeItem.item_id, sizeItem.size)
                                }
                                className={
                                  sizeActive === sizeItem.item_id
                                    ? "sizActive name"
                                    : "name"
                                }
                              >
                                {sizeItem.size}
                              </span>
                            ))}
                            {/* <span className="name">62</span>
                                                            <span className="name">77</span> */}
                          </div>
                        </div>
                      )}
                      <div className="addQty">
                        <span className="pullLeft">{t("Quantity")} :</span>
                        &nbsp;&nbsp;&nbsp;
                        <div className="pullRight">
                          <div className="inputMain fl clearfix">
                            <button onClick={() => changeQuantity("decrease")}>
                              -
                            </button>
                            <input
                              type="text"
                              value={quantity}
                              onChange={(e) => setQuantity(e.target.value)}
                            />
                            <button
                              className="rightB"
                              onClick={() => changeQuantity("increase")}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="popupFooter">
                      <div className="buyNow" onClick={() => gotoCheckout()}>
                        Buy Now
                      </div>
                      <div className="addCart" onClick={addToCartProduct}>
                        Add To Cart
                      </div>
                    </div>
                  </div>
                </Box>
              </Drawer>
            </React.Fragment>
          </div>
        </div>
        {shortdes && (
          <div className="desDetail">
            <span className="nameTitle">{t("Summary")}</span>
            <ReactQuill theme="bubble" value={shortdes} readOnly="true" />
          </div>
        )}
        {longdes && (
          <div className="desDetail">
            <span className="nameTitle">{t("ProductDetail")}</span>
            <ReactQuill theme="bubble" value={longdes} readOnly="true" />
          </div>
        )}
        <div className="commentDisplay">
          <div className="forComment">
            <span className="couponTitle">
              {t("CustomerReview")}&nbsp;&nbsp;({reviewCount})
            </span>
            <span className="couponNumber" onClick={gotocomment}>
              {" "}
              {t("SeeMore")}
            </span>
            <KeyboardArrowRight id="btnRight" />
          </div>
          {reviewProduct.length > 0 &&
            reviewProduct.slice(0, 2).map((reItem, reIndex) => (
              <div className="listwrap" key={reIndex}>
                <div className="listHd">
                  <div className="pullLeft">
                    <img src={reItem.customer_photo} />
                    &nbsp;&nbsp;
                    <span>{reItem.customer_name}</span>
                  </div>
                  <div className="listPurTimer">{reItem.comment_date}</div>
                  <div className="pullRight">
                    {[...Array(reItem.star_count)].map((i) => (
                      <span key={i}>
                        <Star fontSize="small" id="onestar" />
                      </span>
                    ))}
                    {[...Array(5 - reItem.star_count)].map((i) => (
                      <span key={i}>
                        <Star fontSize="small" id="notstar" />
                      </span>
                    ))}
                  </div>
                </div>
                <div className="listCom">{reItem.comment}</div>
                <div className="listImgMain clearfix">
                  {reItem.photo_one && <img src={reItem.photo_one} />}
                  {reItem.photo_two && <img src={reItem.photo_two} />}
                  {reItem.photo_three && <img src={reItem.photo_three} />}
                </div>
              </div>
            ))}
        </div>
        <div className="Question">
          <div className="fortitle">
            <span className="title">
              {t("AskQuestion")}&nbsp;&nbsp;({showCount})
            </span>
            <span className="more" onClick={gotoqa}>
              {" "}
              {t("SeeMore")}
            </span>
            <ChevronRight id="btnRight" />
          </div>
          {showAns.length > 0 &&
            showAns.slice(0, 2).map((all, index) => (
              <div className="allQa" key={index}>
                <div className="writeQ">
                  <ContactSupport id="qicon" />
                  <div className="ques">
                    <div className="showDetail">
                      {all.customer_name != null ? (
                        <span className="name">{all.customer_name}</span>
                      ) : (
                        <span className="name">User : {all.customer_id}</span>
                      )}

                      <span className="date">{all.question_time}</span>
                    </div>
                    <div className="wq">{all.question}</div>
                  </div>
                </div>
                {all.answer != null && (
                  <div className="writeA">
                    <QuestionAnswer id="qicon" />
                    <div className="ques">
                      <div className="showDetail">
                        <span className="name">{all.answer_by}</span>
                        <span className="date">{all.answer_time}</span>
                      </div>
                      <div className="wq">{all.answer}</div>
                    </div>
                  </div>
                )}
              </div>
            ))}

          <div className="btnNext" onClick={gotoqa}>
            {t("AskQuestion")}
          </div>
        </div>
        <div className="related">
          <div className="title">{t("RelatedProducts")}</div>
          <div className="allCard">
            {detailproduct.related_products &&
              detailproduct.related_products
                .slice(0, 6)
                .map((relItem, relIndex) => (
                  <div
                    className="card"
                    onClick={() =>
                      gotoproduct(
                        relItem.id,
                        relItem.item_status,
                        relItem.item_id
                      )
                    }
                    key={relIndex}
                  >
                    {relItem.product_promotion_percentage != null && (
                      <div className="absolute top-2 right-2 text-[12px] text-white rounded-full w-[40px] h-[40px] bg-[#408d7f] flex justify-center items-center">
                        -
                        {Number.parseFloat(
                          relItem.product_promotion_percentage
                        ).toFixed(0)}
                        %
                      </div>
                    )}
                    <div className="cardphoto">
                      <img src={relItem.photo} className="image" />
                    </div>
                    <div className="price items-center flex justify-center !h-[51px]">
                      {relItem.product_promotion_price !== 0 ? (
                        <div className="flex flex-col">
                          <span className="">
                            {relItem.product_promotion_price}ks
                          </span>
                          <s className="oriPrice  text-[#777]">
                            {relItem.price || relItem.simple_price}ks
                          </s>
                        </div>
                      ) : (
                        <span className="">
                          <span className="money pt-10">
                            {relItem.price || relItem.simple_price}ks
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                ))}
          </div>
        </div>
        {sameStore.length > 0 && (
          <ul className="sameStore">
            <li className="storeItem">
              <div className="top">
                <div className="title">{t("SameStore")}</div>
              </div>
              <div className="goodsBox">
                {sameStore.map((sameItem, sameIndex) => (
                  <div
                    className="floorGoods"
                    onClick={() =>
                      gotoproduct(
                        sameItem.id,
                        sameItem.item_status,
                        sameItem.item_id
                      )
                    }
                    key={sameIndex}
                  >
                    <img src={sameItem.photo} />
                    {sameItem.product_promotion_percentage !== null && (
                      <div className="promotionDiscountt">
                        <div className="percent">
                          -
                          {Number.parseFloat(
                            sameItem.product_promotion_percentage
                          ).toFixed(0)}
                          %
                        </div>
                      </div>
                    )}
                    <p className="title">{sameItem.product_name}</p>
                    {sameItem.product_promotion_price !== 0 ? (
                      <p className="price">
                        {sameItem.product_promotion_price}
                        ks
                        <span className="disPrice">
                          {sameItem.simple_price}ks
                        </span>
                      </p>
                    ) : (
                      <p className="price">{sameItem.simple_price}ks</p>
                    )}
                    {sameItem.star_count != null && (
                      <div className="starsdiv">
                        {[...Array(sameItem.star_count)].map((i) => (
                          <span key={i}>
                            <Star fontSize="small" id="onestar" />
                          </span>
                        ))}
                        {[...Array(5 - sameItem.star_count)].map((i) => (
                          <span key={i}>
                            <Star fontSize="small" id="notstar" />
                          </span>
                        ))}
                        ({sameItem.person_count})
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </li>
          </ul>
        )}
        <div className="proFooter">
          {!sizeActive && !sizeAct && detailproduct.item_status == 1 ? (
            <div className="buyNow" onClick={toggleDrawer(true)}>
              {t("BuyNow")}
            </div>
          ) : (
            <div className="buyNow" onClick={() => gotoCheckout()}>
              {t("BuyNow")}
            </div>
          )}
          {!sizeActive && !sizeAct && detailproduct.item_status == 1 ? (
            <div className="addCart" onClick={toggleDrawer(true)}>
              {t("AddToCart")}
            </div>
          ) : (
            <div className="addCart" onClick={addToCartProduct}>
              {t("AddToCart")}
            </div>
          )}
        </div>
        <div className="blance"></div>
      </div>

      <ToastContainer draggable={false} autoClose={3000} />
    </div>
  );
};

export default ProductDetail;

//select size change price

// https://www.youtube.com/watch?v=1DklrGoAxDE

// https://stackoverflow.com/questions/71960259/react-js-ecommerce-price-change-when-size-and-material-variants-option-selected
