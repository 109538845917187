import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Home from "../home/Home";
import SearchProduct from "../home/SearchProduct";
import Product from "../product/Product";
import ProductDetail from "../product/ProductDetail";
import CheckOut from "../product/CheckOut";
import CheckOutMultiple from "../product/CheckOutMultiple";
import Payment from "../product/Payment";
import MultiPayment from "../product/MultiPayment";
import Address from "../address/Address";
import AddCart from "../addToCart/AddCart";
import Login from "../accountSetting/Login";
import Signup from "../accountSetting/SignUp";
import RequestPasswordReset from "../accountSetting/RequestPasswordReset";
import RequestResetPasswordSms from "../accountSetting/RequestResetPasswordSms";
import ResetPassword from "../accountSetting/ResetPassword";
import OrderList from "../account/OrderList";
import { NavigateProvider } from "../context";
import NewArrivalSeeMore from "../seeMore/newArrival/NewArrivalSeeMore";
import CategoryAllProduct from "../seeMore/CategoryAllProduct";
import SubAllProduct from "../seeMore/SubAllProduct";
import GrandAllProduct from "../seeMore/GrandAllProduct";
import OfficialStoreSeeMore from "../seeMore/officialStore/OfficialStoreSeeMore";
import PromotionSeeMore from "../seeMore/promotion/PromotionSeeMore";
import ProductQAS from "../product/ProductQAS";
import LoginWithSms from "../accountSetting/LoginWithSms";
import SocialLoginCallback from "../../components/commons/SocialLoginCallback";
import OrderDetail from "../account/OrderDetail";
import NoCancelDetail from "../account/NoCancelDetail";
import OrderCancleList from "../account/OrderCancleList";
import ReadyToShip from "../account/ReadyToShip";
import ToShip from "../account/ToShip";
import Receive from "../account/Receive";
import Return from "../account/Return";
import ReviewPage from "../account/ReviewPage";
import SeeQA from "../account/SeeQA";
import WishList from "../account/WishList";
import FllowStore from "../account/FllowStore";
import ShowCoupon from "../account/ShowCoupon";
import StorePage from "../storePage/StorePage";
import Message from "../account/Message";
import Loading from "../../components/commons/Loading";
import RouteLayout from "./RouteLayout";
import LastLocaltionRoute from "./LastLocaltionRoute";
import AuthProtectedRoute from "./AuthProtectedRoute";
import About from "../about/About";
import TermPolicy from "../about/TermPolicy";
import PaymentMiddleware from "./PaymentMiddleware";
import OrderCancleMiddleware from "./OrderCancleMiddleware";
import BuyAgainOrderDetail from "../account/BuyAgainOrderDetail";
import HowToSell from "../about/HowToSell";
import CompareProductList from "../product/CompareProductList";
import ServiceShopList from "../seeMore/serviceShop/ServiceShopList";
import ServiceShopDetail from "../seeMore/serviceShop/ServiceShopDetail";

const UserInformation = lazy(() => import("../account/UserInformation")); // import component when it require
const UserProfile = lazy(() => import("../account/UserProfile"));
const UserProfileUpdate = lazy(() => import("../account/UserProfileUpdate"));
const router = () => {
  return (
    <Router>
      <Suspense
        fallback={
          <div className="w-full h-full flex justify-center items-center bg-white">
            <Loading open={true} />
          </div>
        }
      >
        <NavigateProvider>
          <Routes>
            <Route element={<RouteLayout />}>
              <Route element={<LastLocaltionRoute />}>
                <Route exact path="/" element={<Home />} />
                <Route
                  exact
                  path="search-product"
                  element={<SearchProduct />}
                />
                {/* <Route path="products/:productId" element={<ProductDetail />} /> */}
                <Route path="products" element={<Product />} />
                <Route
                  path="products/product-detail/:productId/:itemId?"
                  element={<ProductDetail />}
                />
                <Route
                  path="products/product-detail/:productId/:sellerId"
                  element={<ProductQAS />}
                />
                <Route element={<AuthProtectedRoute />}>
                  <Route path="CheckOut" element={<CheckOut />} />
                  <Route
                    path="multiple-product-checkout"
                    element={<CheckOutMultiple />}
                  />
                  <Route element={<PaymentMiddleware />}>
                    <Route path="Payment" element={<Payment />} />
                  </Route>
                  <Route element={<PaymentMiddleware />}>
                    <Route path="PaymentMultiple" element={<MultiPayment />} />
                  </Route>
                </Route>
                <Route path="AddAddress" element={<Address />} />
                <Route path="ShoppingCart" element={<AddCart />} />
                <Route path="login-with-phone" element={<Login />} />
                <Route path="Signup" element={<Signup />} />
                <Route path="Login-Sms" element={<LoginWithSms />} />
                <Route
                  path="request-reset-password"
                  element={<RequestPasswordReset />}
                />
                <Route
                  path="sms-reset-password"
                  element={<RequestResetPasswordSms />}
                />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route element={<AuthProtectedRoute />}>
                  <Route path="OrderList" element={<OrderList />} />
                  <Route path="OrderCancelList" element={<OrderCancleList />} />
                  <Route
                    path="UserProfileUpdate"
                    element={<UserProfileUpdate />}
                  />
                  <Route path="SeeQA" element={<SeeQA />} />
                </Route>
                <Route path="UserInformation" element={<UserInformation />} />
                <Route path="UserProfile" element={<UserProfile />} />
                <Route path="NewArivial" element={<NewArrivalSeeMore />} />
                <Route
                  path="CategoryAllProduct/:categoryId"
                  element={<CategoryAllProduct />}
                />
                <Route
                  path="SubAllProduct/:subId"
                  element={<SubAllProduct />}
                />
                <Route
                  path="GrandAllProduct/:grandId"
                  element={<GrandAllProduct />}
                />
                <Route path="StoreAll" element={<OfficialStoreSeeMore />} />
                <Route path="PromotionMore" element={<PromotionSeeMore />} />
                <Route path="ServiceShopList" element={<ServiceShopList />} />
                <Route path="ServiceShop/:shopId" element={<ServiceShopDetail />} />
                <Route
                  path="auth/:provider"
                  element={<SocialLoginCallback />}
                />
                <Route element={<OrderCancleMiddleware />}>
                  <Route
                    path="OrderDetail/:orderID"
                    element={<OrderDetail />}
                  />
                </Route>
                <Route
                  path="NoCancelDetail/:orderID"
                  element={<NoCancelDetail />}
                />
                <Route
                  path="cancelDetail/:orderID"
                  element={<BuyAgainOrderDetail />}
                />
                <Route path="ReadyToShip" element={<ReadyToShip />} />
                <Route path="ToShip" element={<ToShip />} />
                <Route path="Receive" element={<Receive />} />
                <Route path="Return" element={<Return />} />
                <Route path="ReviewHistory" element={<ReviewPage />} />
                <Route path="Wishlist" element={<WishList />} />
                <Route path="FllowStore" element={<FllowStore />} />
                <Route path="Coupon" element={<ShowCoupon />} />
                <Route path="StoreShop/:sellerId" element={<StorePage />} />
                <Route path="Message" element={<Message />} />
                <Route path="MgdAbout" element={<About />} />
                <Route path="TermPolicy" element={<TermPolicy />} />
                <Route path="howToSell" element={<HowToSell />} />
                <Route path="CompareList" element={<CompareProductList />} />
              </Route>
            </Route>
          </Routes>
        </NavigateProvider>
      </Suspense>
    </Router>
  );
};

export default router;
