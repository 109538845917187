import { createSlice } from "@reduxjs/toolkit";

export const appState = createSlice({
  name: "app",
  initialState: {
    appLoading: false,
    confirmOrderPaymentRequest: null,
    compareProducts: [],
  },
  reducers: {
    updateConfirmOrderPaymentRequest: (state, action) => {
      state.confirmOrderPaymentRequest = action.payload;
    },
    updatecompareProducts: (state, action) => {
      state.compareProducts = action.payload;
    }
  }
});

export const {
  updateConfirmOrderPaymentRequest,
  updatecompareProducts
} = appState.actions;
export default appState.reducer;
