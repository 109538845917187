import React, { useState } from "react";
import "./LoginCss.scss";
import Wrapper from "../Wrapper";
import Lottie from "lottie-react";
import loginCover from "../../assets/loginCover.json";
import {
  Phone,
  Lock,
  Visibility,
  VisibilityOff,
  Facebook,
  Google,
} from "@mui/icons-material";
import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import CutomErrorMessage from "../../components/commons/CutomErrorMessage";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { setAuthUser } from "../../reducers/user";
import { requestLoginWithPhoneAsync } from "../../reducers/userActions";
import Loading from "../../components/commons/Loading";
import getCustomerCartItems from "../../mobile/helper/CustomerCart";

const userInfoSchema = Yup.object({
  phone: Yup.string().required().label("Phone Number"),
  password: Yup.string().required().min(6).matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,"At least one number, one samll and capital letter").label("Password"),
});

const Login = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.user);
  const [errors, setErrors] = useState(null);

  const gotoSignUp = () => {
    navigate("/Signup");
  };
  const requestResetPassword = () => {
    navigate("/request-reset-password");
  };
  const phoneLogin = async ({ phone, password }) => {
    let requestObj = {
      method: "post",
      url: ApiCollections.loginWithPhone,
      params: {
        phone,
        password,
      },
    };
    setLoading(true);
    try {
      setErrors(null);
      const response = await dispatch(
        requestLoginWithPhoneAsync(requestObj)
      ).unwrap();
      setLoading(false);
      if (response.flag === false) {
        setErrors(response.message);
      } else if (response.data.code === 200) {
        dispatch(setAuthUser(response.data.data)); //set aut user data to state
        // getCustomerCartItems(response.data.data.user.id);
        // toast.success(response.data.message);
        // localStorage.setItem("userTokenID", response.data.data.user.id);
        // localStorage.setItem("userLoginStatus", "phone");
        // navigate("/");
        navigate("/Login-Sms");
      } else {
        toast.error("Something Wrong Login with Phone!");
      }
    } catch (err) {
      setLoading(false);
      toast.error(`Fetch failed: ${err.message}`);
    }
  };
  const handleGoogleLoginApi = async () => {
    let requestObj = {
      method: "get",
      url: ApiCollections.loginWithSocial + `/google`,
    };
    setLoading(true);
    const response = await ApiRequest(requestObj);
    setLoading(false);
    if (response.flag === false) {
      setErrors(response.message);
    } else if (response.data.code === 200) {
      window.location.replace(response.data.data.url);
      localStorage.setItem("userTokenID", response.data.data.user.id);
      localStorage.setItem("userLoginStatus", "google");
    } else {
      toast.error("Something Wrong Login with Phone!");
    }
  };
  const handleFacebookLoginApi = async () => {
    let requestObj = {
      method: "get",
      url: ApiCollections.loginWithSocial + `/facebook`,
    };
    setLoading(true);
    const response = await ApiRequest(requestObj);
    setLoading(false);
    if (response.flag === false) {
      setErrors(response.message);
    } else if (response.data.code === 200) {
      window.location.replace(response.data.data.url);
      localStorage.setItem("userTokenID", response.data.data.user.id);
      localStorage.setItem("userLoginStatus", "facebook");
    } else {
      toast.error("Something Wrong Login with Facebook!");
    }
  };

  return (
    <Wrapper>
      {loading && (
        <div className="w-full h-[100vh] flex justify-center items-center bg-white">
          <Loading open={loading} />
        </div>
      )}
      <Container>
        <div className="loginDiv">
          <ToastContainer autoClose={3000} />
          {error && toast.error(error)}
          {errors &&
            errors.map((error, index) => {
              toast.error(error);
            })}
          <div className="leftBox">
            <div className="loginTitle">{t("LOGIN")}</div>

            <Formik
              initialValues={{ phone: "", password: "", confirm_password: "" }}
              validationSchema={userInfoSchema}
              onSubmit={(values) => {
                phoneLogin(values);
              }}
            >
              {({ handleChange, handleSubmit, errors }) => (
                <Form>
                  <div className="userBox">
                    <div className="name">{t("phoneNumber")}</div>
                    <div className="enterBox">
                      <Phone id="photo" />
                      <input
                        type="text"
                        placeholder={t("pleasePhone")}
                        className="inputEnter"
                        name="phone"
                        onChange={handleChange("phone")}
                      />
                    </div>
                    {errors.phone && <CutomErrorMessage error={errors.phone} />}
                  </div>
                  <div className="passwordBox">
                    <div className="name">{t("PASSWORD")}</div>
                    <div className="enterBox">
                      <Lock id="photo" />
                      <input
                        placeholder={t("pleasePassword")}
                        className="inputEnter"
                        type={isRevealPwd ? "text" : "password"}
                        name="password"
                        onChange={handleChange("password")}
                      />
                      {isRevealPwd ? (
                        <Visibility
                          id="eyepho"
                          onClick={() =>
                            setIsRevealPwd((prevState) => !prevState)
                          }
                        />
                      ) : (
                        <VisibilityOff
                          id="eyepho"
                          onClick={() =>
                            setIsRevealPwd((prevState) => !prevState)
                          }
                        />
                      )}
                    </div>
                    {errors.password && (
                      <CutomErrorMessage error={errors.password} />
                    )}
                  </div>
                  {/* <div className="forgot">Forgot Password?</div> */}
                  <div className="btnLog" onClick={handleSubmit}>
                    {t("LOGIN_BUTTON")}
                  </div>
                </Form>
              )}
            </Formik>
            <div className="orDiv">
              <span className="orline"></span>
              <span className="ortext">{t("OR")}</span>
              <span className="orliner"></span>
            </div>

            <div onClick={handleFacebookLoginApi} className="btnLogFB">
              <Facebook id="iconf" />
              {t("LOGIN_FACEBOOK")}
            </div>
            <div onClick={handleGoogleLoginApi} className="btnLogGM">
              <Google id="iconf" />
              {t("LOGIN_GOOGLE")}
            </div>
          </div>
          <div className="rightBox">
            <Lottie animationData={loginCover} loop={true} id="logCov" />
            <div className="resetDiv" onClick={requestResetPassword}>
              {t("FORGET_YOUR_PASSWORD")}
              <span>&nbsp;{t("RESET_PASSWORD")}</span>
            </div>
            <div className="phoneDiv" onClick={gotoSignUp}>
              {t("DO_NOT_HAVE_ACCOUNT")}
              <span>&nbsp;{t("REGISTER")}</span>
            </div>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default Login;
