import React, { useState, useEffect, memo } from "react";
import "../../mobile/compare/CompareProductCard.scss";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation } from "react-router-dom";
// swiper package

const CompareProductCard = ({detailproduct}) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const goShop = (id) => {
    navigate(`/StoreShop/${id}`);
  };

  return (
    <div>
      { detailproduct.product_file && 
        <div className="flvd">
          {
            detailproduct.product_file && (
              <div className="proPhoto">
                <img src={detailproduct?.product_file?.[0]?.photo} className="proImg" />
              </div>
            )
          }
        </div>
      }

      <div className="describe">
          <p className="descriptionFn">{detailproduct?.product_name}</p>
          <p className="price">
            {
              detailproduct?.product_promotion_price !== 0 ? (
                <span className="ori">
                  {detailproduct?.product_promotion_price} ks &nbsp;&nbsp;
                  <s className="sdel">{detailproduct?.product_price}ks</s>
                </span>
              ) : (
                <span className="ori">{detailproduct?.product_price} ks</span>
              )
            }
          </p>
        <div className="shopName">
          <span
            className="afix"
            onClick={() => goShop(detailproduct.seller_id)}
          >
            {t("ShopName")} :
          </span>
          &nbsp;
          <span
            className="asin"
            onClick={() => goShop(detailproduct.seller_id)}
          >
            {detailproduct.shop_name}
          </span>
          <span>
            {detailproduct.stock == 0 ? (
              <span className="outStock">{t("OutOfStock")}</span>
            ) : (
              <span className="outStock">{t("InStock")}</span>
            )}
          </span>
        </div>
      </div>

      <div className="desDetail">
        <span className="nameTitle">{t("Summary")}</span>
        <ReactQuill theme="bubble" value={detailproduct?.product_description} readOnly="true" />
      </div>

      <div className="desDetail">
        <span className="nameTitle">{t("ProductDetail")}</span>
        <ReactQuill theme="bubble" value={detailproduct?.product_longdescription} readOnly="true" />
      </div>
        
      </div>
  );
};

export default memo(CompareProductCard);
